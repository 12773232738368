import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/Connect/Token',
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: `grant_type=password&username=${data.username}&password=${data.password}`
    })
}

export async function apiUserInfo(data) {
    return ApiService.fetchData({
        url: '/Connect/UserInfo',
        method: 'get',
        data,
    })
}

export async function apiChangePassword(data) {
    return ApiService.fetchData({
        url: '/Account/ChangePassword',
        method: 'post',
        data
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/Account/ForgotPassword',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/Account/ResetPassword',
        method: 'post',
        data,
    })
}

export async function apiEmailConfirm(data) {
    return ApiService.fetchData({
        url: `/Account/ConfirmEmail?token=${data.token}&email=${data.email}`,
        method: 'get',
    })
}