const appConfig = {
    apiPrefix: 'https://app-nnext-prod.azurewebsites.net/',
    blobPrefix: 'https://stnnextprod.blob.core.windows.net',
    authenticatedEntryPath: '/dashboard/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    locale: 'pl',
    enableMock: false,
}

export default appConfig
